import React, { useContext, useEffect, useState } from "react";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";
import styled from "styled-components";
import { Link, graphql, navigate } from "gatsby";
import { use100vh } from "react-div-100vh";
import base64 from "base-64";

// Context
import { PageSetup } from "../components/context/page-context";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 51px 0 0 0;
  min-height: calc(${props => props.height}px - 50px - 50px);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  color: #0b646f;

  & .page-container {
    max-width: 440px;
    margin: 0 auto;
  }

  & h1,
  & p {
    font-size: 22px;
    line-height: 32px;
    text-align: center;

    & a {
      color: #debb9b;
    }
  }

  @media (max-width: 960px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    & h1,
    & p {
      font-size: 12px;
      line-height: 17px;
    }
  }
`;

const NotFoundPage = ({ location, data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const height = use100vh();

  useEffect(() => {
    setPageSetup({
      pageTitle: ``,
      pageColor: `#DDE1D4`,
      pageType: `not-found`,
      filterArtists: [],
      selectedArtists: [],
      sortOption: null,
      priceOption: null,
      artistOption: [],
      applyFilter: false,
    });
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes(`/invoices/`) &&
      typeof window !== undefined
    ) {
      window.location = `https://houseofvoltaire.myshopify.com/${location.pathname}`;
    }
  }, [location]);

  if (location.pathname.includes(`/invoices/`)) {
    //  && isNotFound === true
    return (
      <Page height={height}>
        <div className="page-container">
          <p className="news-gothic">Redirecting you to the checkout...</p>
        </div>
      </Page>
    );
  } else {
    //  if (isNotFound === true)
    return (
      <>
        <PageSeo
          seoTitle={`404 Page Not Found`}
          seoText={null}
          seoImage={null}
        />

        <Page height={height}>
          <div className="page-container">
            <h1>404 Page Not Found</h1>
            <p className="news-gothic">
              The page you requested does not exist. Click{" "}
              <Link to={`/shop/`}>here</Link> to continue shopping.
            </p>
          </div>
        </Page>
      </>
    );
  }
};

// export const query = graphql`
//   {
//     allShopifyProduct(filter: { status: { eq: ACTIVE } }) {
//       edges {
//         node {
//           handle
//           variants {
//             shopifyId
//           }
//         }
//       }
//     }
//   }
// `;

export default withPrismicUnpublishedPreview(NotFoundPage);
